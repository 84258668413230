import React from 'react';
import styled from 'styled-components';

export const Container=styled.div`
height:100vh;
flex:1;
display:flex;
background-color: BLACK;
flex-direction: column;
justify-content: center;
`;
export const A=styled.div`
height:50%;
margin-left: 10VW;
margin-right: 10VW;
background-image: url('https://i.ibb.co/XzmTxTy/image00001.jpG');
background-repeat: no-repeat;
background-size: 100% 100%;
background-color: BLACK;

border-top: WHITE solid 5px;
border-left: WHITE solid 5px;
  border-right: WHITE solid 5px;
  text-align: center;
  color: WHITE;
  z-index: 0; 
`
export const B=styled(A)`

background-image: url('https://i.ibb.co/0MjVZn1/image00019.jpG');
border-top: WHITE solid 0px;

border-bottom: WHITE solid 5px;

`;
export const Large=styled.h1`
margin-top: 3vh;
letter-spacing: 7px;
font-size: 50px;
@media screen and (max-width: 600px){
    font-size: 40px;
}
@media screen and (max-height: 605px){
    font-size: 30px;
}
`
export const Medium=styled.h1`
margin-top: 2.5vh;
letter-spacing: 5px;
font-size: 35px;
@media screen and (max-width: 600px){
    font-size: 28px;
}
@media screen and (max-height: 605px){
    font-size: 20px;
}
`
export const Small=styled.h1`
margin-top: 4.5vh;
letter-spacing: 2px;
font-size: 20px;
@media screen and (max-width: 600px){
    font-size: 16px;
}
@media screen and (max-height: 605px){
    font-size: 10px;
}
`
export const XSmall=styled.h1`
margin-top: 2vh;
letter-spacing: 1px;
font-size: 15px;
@media screen and (max-width: 600px){
    font-size: 10px;
}
@media screen and (max-height: 605px){
    font-size: 8px;
}
`