import React from 'react';
import Adv from '../Components/Adv/Adv';

import Content from '../Components/Content/Content';

import Hero from '../Components/Hero/Hero';

import Neon2 from '../Components/Neon2/Neon2';
import Neon from '../Components/Neonmenu/Neon';
function Home
() {
	return <>
    
    <Hero/>

    <Content/>
<Neon/>
<Adv></Adv>

<Neon2></Neon2>

    </>
    ;
}

export default Home
;
