import React from 'react';
import { Drink,Drink1,Drink2,Drink3,Web, NeonContainer, NeonSection, NeonText, NeonText2, NeonVideo, Mobile, NeonText3, NeonText4 } from './Neonstyles';
import { Component } from 'react';
import nv from './4k neon frame video loop background no copyright(4K_HD).mp4'
import drink from '../../assets/ceb69993c47f426fe0d39414b3c0c291-removebg-preview.png'
import drink2 from '../../assets/Untitled__6_-removebg-preview (1).png'

export class Neon extends Component{
  state={
		a:true,
	}

    render(){
    return (
      
            <NeonSection>
           
		<NeonVideo src={nv} muted={true} loop autoPlay playsInline={true} >
      
	</NeonVideo>
    <NeonContainer>
    <Drink1 src={drink2}></Drink1>
    <Drink3 src={drink}></Drink3>
      <Mobile>Cocktails</Mobile>
    <NeonText3>   <Web> Cocktails</Web> 
    <Drink2 src={drink2}></Drink2>
    <NeonText4>
 
    <div>
    <br></br>
        -MOJITO<br></br>
        -NEGRONI<br></br> <Mobile> <br></br></Mobile>
      
        -NIRVANA<br></br>
      -CAIPIRNHA<br></br>  <Mobile> <br></br></Mobile>
      -BULL FROG<br></br>
      -MARGARITA  <br></br>  <Mobile> <br></br></Mobile>
      
     
       -WHISKY SOUR<br></br> 
       -MOSCOW MULE<br></br><Mobile> <br></br></Mobile>
       -PUERO DE INDIA <br></br>
     
    
     
        </div>
        <div>
        <Web>        <br></br>
</Web>
  -CAMPARI SPRITZ<br></br> <Mobile> <br></br></Mobile>
  -APERROL SPRITZ<br></br> 
      -MOJITO DE FRESA<br></br>
     
    <Mobile> <br></br></Mobile>
    -GIN TONIC CLASSIC<br></br> 
      
      -ESPRESSO MARTINI<br></br>  <Mobile> <br></br></Mobile>
        -PORNSTAR MARTINI<br></br>
        -SEX ON THE BEACH <br></br> <Mobile> <br></br></Mobile>
         -MOJITO STRAWBERRY<br></br> 
         
       
       
        </div>
    </NeonText4>
 
       </NeonText3>
       <Drink1 src={drink}></Drink1>
 


    </NeonContainer>
            </NeonSection>
    );};
}

export default Neon;
