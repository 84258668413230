import React from 'react';
import { MainHeading } from '../Content/Contentstyles';
import { A, B, Container, Large, Medium, Small, XSmall } from './Advstyles';
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const text= {
    visible: { opacity:5, transition: { duration: 4 } },
    hidden: {  opacity:0}
  };


const Adv = () => {
    const control = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);
    return (

        <Container>

<A>
<Medium>CRAZY</Medium>
    <Large>HOUR </Large>
    <Small>BEER 1€</Small>
    <Small>- TUESDAY - </Small>
    <XSmall>9:00 PM - 11:00 PM</XSmall>
  
</A>
<B>
<Large>LADIES </Large>
<Medium>NIGHT</Medium>
<XSmall>FIRST DRINK FOR FREE</XSmall>
    <XSmall>SECOND DRINK 10% DISCOUNT</XSmall>
    <Small>- WENSDAY - </Small>
    <XSmall>FULL NIGHT</XSmall>

</B>
        </Container>
     
    );
}

export default Adv;
