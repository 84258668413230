import React from 'react';
import { Globalstyle } from './Globalstyles';
import { BrowserRouter ,Switch,Rotue, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
function App() {
	return <BrowserRouter>
	<Globalstyle/>
	<switch>
		<Navbar/>
	<Route exact path="/" component={Home}></Route>

	</switch>
	<Footer/>
	</BrowserRouter>;
}

export default App;
