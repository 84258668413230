import styled from "styled-components" ; 
import back from'../../assets/photo-1575444758702-4a6b9222336e.jpeg';
export const HeroSection= styled.div ` 
height : 100vh ; 
background-position : center ;
 background-size:cover ; 
  display : flex ;
   align-items : center ; 
   box-shadow : inset 0 0 0 1000px rgba ( 0 , 0 , 0 , 0.2 ) ; 
   object-fit :cover ;
     @media screen and (max-width:968px){
        font-size : 15px;
        height: 60vh;

   
  }`
    export const HeroText =styled.p `
    margin-bottom : 35px ;
     font-size : 20px;
  line-height : 24px ; 
  text-align : center ;
   letter-spacing : 2px ; 
  color : white ;
  @media screen and (max-width:968px){
        font-size : 15px;
        

   
  }`
  export const Buttonwrapper =styled.div `
  width : 100% ; 
  display : flex ; 
  justify-content : center ;
   flex-flow : wrap ; 
   gap : 0.5em ;`