import styled from "styled-components";
export const NeonSection= styled.div ` 
height : 100vh ; 
background-position : center ;
 background-size:cover ; 
  display : flex ;
   align-items : center ; 
   object-fit :cover ;
   justify-content: center;
   background-color: black;
   `

export const NeonVideo = styled.video`
object-fit: fill;
width: 100%;
height: 100vh;
position: relative;
z-index: 0;
@media screen and (max-width: 968px) {
    object-fit: fill;
    width: 100%;
    height: 100vh;
}`;
export const Drink=styled.img`
margin-left: 5%;
margin-right: 5%;
height:180px;
width:100px;
@media screen and (max-width: 968px) {
    height:80px;
    margin-left: 0%;
margin-right: 0%;
width:50px;
}
`;
export const Drink1=styled.img`
height:180px;
width:100px;
margin-left: 5%;
margin-right: 5%;
@media screen and (max-width: 968px) {
    height:0px;
width:0px;};
`;
export const Drink2=styled.img`
margin-left: 0%;
margin-right: 0%;
height:0px;
width:0px;
@media screen and (max-width: 968px) {
    margin-left: 0%;
margin-right: 0%;
    height:120px;
width:50px;};`;
export const Drink3=styled.img`
margin-left: 0%;
margin-right: 0%;
height:0px;
width:0px;
@media screen and (max-width: 968px) {
    margin-left: 0%;
margin-right: 0%;
    height:80px;
width:50px;};`;


export const NeonContainer=styled.div`
position: absolute;
display:flex;
color: white;
flex-direction: row;
flex: 1;
justify-content: center;
align-content: center;
align-items: center;
text-align: center;
width:87%;
height: 80%;
background-color: none;
@media screen and (max-width: 968px) {
    font-size:20px;
    flex-direction: column;

}
`;
export const NeonText=styled.div`
width:100%;

text-align:center;
color:white;
font-size:35px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media screen and (max-width: 968px) {
    font-size:20px;
    flex-direction: column;
}
`
export const NeonText3=styled.div`
width:100%;

text-align:center;
color:white;
font-size:35px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


@media screen and (max-width: 968px) {
    font-size:20px;
    flex-direction: row;
}
`
export const NeonText2=styled.div`
width:85%;
height: 85%;
text-align:center;
color:white;
font-size:22px;
display: flex;
flex-direction: row;
justify-content: space-around;
@media screen and (max-width: 968px) {
    font-size:10px;
    flex-direction: column;
}
`
export const NeonText4=styled.div`
width:85%;
height: 85%;
text-align:center;
color:white;
font-size:22px;
display: flex;
margin-left:-50px;
flex-direction: row;
justify-content: space-around;
@media screen and (max-width: 968px) {
    font-size:10px;
    flex-direction: column;
}
`
export const Web =styled.div`
z-index: 1;
text-align: center;
@media screen and (max-width: 968px) {
    z-index: -99;
    width: 0;
    height: 0;

}
`
export const Mobile =styled.div`

    width: 0;
    height: 0; z-index: -99;

@media screen and (max-width: 968px) {
    height: 20px;
    width: 100%;
    z-index: 1;
    text-align: center;
}
`