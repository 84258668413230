import React from 'react';
import { Drink2,Drink1,Drink, NeonContainer, NeonSection, NeonText, NeonText2, NeonVideo, Web, Mobile } from '../Neonmenu/Neonstyles';
import { Component } from 'react';
import nv from '../Neonmenu/4k neon frame video loop background no copyright(4K_HD).mp4'
import drink1 from '../../assets/Untitled__5_-removebg-preview (1).png'
import shot from '../../assets/Untitled__7_-removebg-preview.png'
const Neon2 = () => {

    return (
        <NeonSection>
              
		<NeonVideo src={nv} muted={true} loop autoPlay playsInline={true}>
      
	</NeonVideo>
    <NeonContainer>
    <Drink src={shot}></Drink>
    <Mobile>CHUPITO</Mobile>
    <NeonText> 
    <Web>
    <br></br>
    CHUPITO</Web>
    
 
  

    
    <NeonText2>
 
    <div>
    <br></br>
        -B52<br></br><br></br>
        -BABY GUINNES<br></br><br></br>
      -KAMIKAZE<br></br><br></br>
        </div>
        <div>
        <Web><br></br></Web> 

        -CHUPITO CLASICO<br></br><br></br>
       -BENDITA LOCURA<br></br><br></br>
        -CHUPITO E.T<br></br><br></br>
        </div>
      
    </NeonText2>
    <Web>BEER</Web>
    <Drink2 src={drink1}></Drink2>
    <Mobile>BEER</Mobile>
      
 

   
       <NeonText2>
       <div>
    <br></br>
        -CORONA<br></br><br></br>
        -DAURA<br></br><br></br>
      -FREE DAMM<br></br>

        </div>
        <div>
        <Web><br></br></Web> 
        -SPUP IPA<br></br><br></br>
       -OCATA<br></br><br></br>
        </div>

       </NeonText2>
       </NeonText>
       <Drink1 src={drink1}></Drink1>


    </NeonContainer>
            </NeonSection>
    );
}

export default Neon2;
