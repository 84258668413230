export const data = [
	{
		to: '/',
		text: 'HOME',
	
	},
	{
		to: '/',
		text: 'ABOUT',
	},
	{
		to: '/',
		text: 'CONTACT',
	},
];
