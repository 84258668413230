import styled ,{createGlobalStyle}from'styled-components';
import {Redcolor} from '../../data/GlobalData'; 

export const Section=styled.div`

background-color: black;
display: flex;
flex:1;
align-items:stretch;


`;
export const Leftside=styled.div`
color: white;
background-color: black;
display:flex;
flex-direction: column;
width:50%;
justify-content: center;

padding:2rem 2rem 2rem 2rem;
@media screen and (max-width: 500px){

}


`;
export const Rightside=styled(Leftside)`
align-items: center;
text-align:center;
@media screen and (max-width: 500px){

}

`
;
export const Headline=styled.div`
font-size: 20px;
color:${Redcolor};
padding: 1rem 0rem 0rem 3rem;
@media screen and (max-width: 800px){
    font-size: 15px;
    padding: 1rem 0rem 0rem 1.5rem;

}
@media screen and (max-width: 500px){
    font-size: 13px;
    padding: 1rem 0rem 0rem 0rem;

    
    
}
`;
export const MainHeading=styled.div`
font-size: 35px;
color:white;
padding: 1rem 0rem 0.5rem 3rem;
@media screen and (max-width: 800px){
    font-size: 30px;
    padding: 1rem 0rem 0.5rem 1.5rem;

}
@media screen and (max-width: 500px){
    font-size: 20px;
    padding: 1rem 0rem 0.5rem 0rem;

}
@media screen and (max-width: 400px){
    font-size: 13px;
    padding: 1rem 0rem 0.5rem 0rem;

}
`;
export const DetailHeading=styled.div`
font-size: 25px;
letter-spacing: 2px;
color:whitesmoke;
padding: 1rem 0rem 1.5rem 3rem;
@media screen and (max-width: 800px){
    font-size: 12px;

    letter-spacing: 0px;
    padding: 1rem 0rem 1.5rem 1.5rem;
}
@media screen and (max-width: 500px){
    font-size: 10px;

letter-spacing: 0px;
    padding: 1rem 0rem 1.5rem 0rem;
}
@media screen and (max-width: 400px){
    font-size: 8px;
    padding: 1rem 0rem 1.5rem 0rem;
}
`;

export const Buttonwrapper =styled.div `
width : 100% ; 
height:20px;
display : flex ; 
justify-content : center ;
 flex-flow : wrap ; 
 `
 export const Image=styled.img`
height: 70%;
width: 75%;
 @media screen and (max-width: 968px){
    width: 100%;

}
 @media screen and (max-width: 505px){
}
 `;