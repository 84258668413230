import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterContainer,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../Globalstyles';
import logo from '../../assets/moh.png';

function Footer() {
	return (
	<FooterContainer>
			<FooterWrapper>
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo to="/">
							<SocialIcon src={logo}/>
						
						</FooterLogo>
						<FooterAddress target="_blank" href='https://www.google.com/maps/place/Bendita+locura/@41.3902707,2.1580777,17z/data=!4m5!3m4!1s0x12a4a345e91d10bd:0x5ddc67051063d113!8m2!3d41.389659!4d2.1580133?hl=es-ES'>
						Bendita locura, Carrer d'Aribau, 86, Barcelona , Spain
						</FooterAddress>

						<Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon
									key={index}
									href="https://www.instagram.com/bendita_locura_bcn/"
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row>
					</FooterColumn>
					{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
							{footerItem.links.map((link, linkIndex) => (
								<FooterLink key={linkIndex} to="/">
									{link}
								</FooterLink>
							))}
						</FooterLinkItems>
					))}
				</FooterGrid>
			
			</FooterWrapper>
			</FooterContainer>
	);
}

export default Footer;
