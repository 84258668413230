 import React from 'react' ; 
 import {Link } from 'react-router-dom' ; 
 import {Button , Container , MainHeading } from '../../Globalstyles';
 import {HeroSection , HeroText , Buttonwrapper } from './HeroStyles' ; 
 import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import Application from '../Application';

const text= {
  visible: { y:20, transition: { duration: 1.5 } },
  hidden: {  y:100}
}; 
 const Hero =() => {
    const control = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);
    return ( 
 <HeroSection > 
  
 <Application/>
 <Container >  
 <motion.div
      ref={ref}
      variants={text}
      initial="hidden"
      animate={control}
    >
    

  <MainHeading > La locura es el goce del ser </MainHeading >
  <HeroText > Los locos abren los caminos que más tarde recorren los sabios. </HeroText> 
  <Buttonwrapper>
    <Link to="/">
        <Button>Find More</Button>
    </Link>
  </Buttonwrapper> </motion.div>
  </Container> </HeroSection > 
    )};
    export default Hero;