import React from 'react';
//import styled from "styled-components";

import GlobalStyles from './GlobalStyles';

import ImageSlider from './ImageSlider';
import image1 from "../../assets/image00001.jpeg";
import image2 from "../../assets/image00027.jpeg";
import image3 from "../../assets/IMG_9744.jpeg";
import image4 from "../../assets/image00006.jpeg";
import image5 from "../../assets/IMG_9746.jpeg";
import image6 from "../../assets/image00002.jpeg";
import './index.css';
const Application = () => {
  return (
    <>
   
      <GlobalStyles />
      <div>
        <ImageSlider images={[image1, image2, image3, image4, image5, image6]}>


          <div className="text-box"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#fff",
            }}
          >
            
           
          </div>
        </ImageSlider>
      </div>
    </>
  );
};

export default Application;
