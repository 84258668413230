import React from 'react';
import{Button} from '../../Globalstyles';
import {Link } from 'react-router-dom' ; 
import right from '../../assets/image00033.jpg';
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion"
import {Image,Buttonwrapper,DetailHeading,Headline,Leftside, MainHeading, Rightside, Section } from './Contentstyles';


const Content = () => {
  
    return (               
          
            <Section>
       <Leftside>
            <Headline>BEATS & BARS</Headline>
            <MainHeading>Our Signature<br></br> Cocktails</MainHeading>
            <DetailHeading>Cocktail is an iced drink of wine or alcoholic beverages mixed with flavorful ingredients.

While that is a very broad definition, it reflects the modern practice of referring to almost any beverage mixed as a cocktail.

To be more precise, Cocktail is a refreshing drink, blended with spirits of any kind, sugar, water, and bitters.</DetailHeading>
<Buttonwrapper>
    <Link to="/">
        <Button>Find More</Button>
    </Link>
  </Buttonwrapper> 
            </Leftside>
            
            <Rightside>
            <Image src={right}></Image>

            </Rightside>
            </Section>
    );
}

export default Content;
